/* Bootstrap extensions */

/* Alert */

.alert {
    border-radius: 2px;
}

/* Categories */

.hn-museum-cat-dropdowns {
    margin-bottom: 20px;
}

.hn-museum-cat-dropdowns .dropdown .btn {
    text-align: left;
    min-height: 34px;
    padding-right: 20px;
    position: relative;
}

.hn-museum-cat-dropdowns .dropdown .caret {
    position: absolute;
    right: 8px;
    top: 12px;
}

hn-dropdown.ng-invalid button{
    border: 1px solid #a94442;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}

.hn-dropdown-invalid {
    color: #a94442;
}

.hn-dropdown-none {
    color: #aaa !important;
}
/* Forms */

.hn-copyable-text[readonly] {
    border-color: #ccc;
    cursor: text;
}

.hn-form-group-spacer,
.form-horizontal > fieldset {
    margin-bottom: 25px;
    margin-top: 25px;
}

.hn-form-group-spacer {
    margin-bottom: 40px; /* 25px Fieldset + 15px Form Group */
}

/* Prevent double margins at the bottom of a fieldset */
.form-horizontal fieldset .form-group:last-child div:last-child .table {
    margin-bottom: 0;
}

legend.hn-legend-indent {
    padding-left: 5px;
    padding-right: 5px;
}

/* Modal */

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
}

.modal-dialog.hn-modal-maxheight {
    height: 100vh;
}
.hn-modal-maxheight .modal-content {
    display: table;
    height: 90%;

    width: 100%; /* ian */
}
.hn-modal-maxheight .modal-content > form {
    display: table;
    height: 100%;
    width: 100%;
}
.hn-modal-maxheight .modal-header,
.hn-modal-maxheight .modal-body,
.hn-modal-maxheight .modal-footer {
    display: table-cell;
}
.hn-modal-maxheight .modal-body {
    height: 100%;
    position: relative;
}
.hn-modal-maxheight .modal-header,
.hn-modal-maxheight .modal-footer {
    height: 1px;
}
.hn-modal-maxheight .modal-cell {
    display: table-row;
}

.modal-body-scroll {
    bottom: 10px;
    overflow-y: auto;
    left: 10px;
    position: absolute;
    right: 10px;
    top: 10px;
}

.hn-modal-image-library .modal-body .img-thumbnail {
    border-radius: 0;
    max-height: 240px;
    max-width: 190px;
    vertical-align: top;
}

/* top navigation */
#admin-top-nav {
    display: none;
}

.admin #top-nav {
    display: none;
}

.admin #admin-top-nav {
    display: block;
}

/* tag components */
.hatch-tag-list {
    list-style-type: none;
    padding: 0;
    text-align: center
}

.hatch-tag {
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 3px;
    display: inline-block;
    padding: 3px 6px 3px 6px;
    margin: 3px;
}

.hatch-tag.selected {
    background-color: #49C;
}

.hatch-tag-result-list {
    list-style-type: none
}

.hatch-tag-result-list > li > h3 {
    border-bottom: 1px solid;
    text-align: center
}

.hatch-tag-result-list .hatch-tag-list {
    text-align: left
}

/* Tag Display */

.hn-list-tags li {
    font-size: 100%;
    margin: 0 1em 0.25em 0;
    position: relative;
}

/* Thumbnails */

.hn-img-thumbnail-frame {
    display: inline-block;
    margin: 7px 7px 0;
    position: relative;
    vertical-align: middle;
}

.hn-img-thumbnail-frame.selected {
    box-shadow: 0 0 10px rgb(102,175,233);
}

.hn-img-thumbnail-frame.selected img.img-thumbnail {
    background-color: rgb(102,175,233);
    border-color: rgb(102,175,233);
    transition: none;
}

/* Primary Image */

.hn-record-media-primary a {
    display: inline-block;
}

.media-left.hn-record-media-primary.hn-img-thumbnail-frame {
    display: table-cell;
}

.hn-record-media-primary img {
    margin-bottom: 10px;
    max-height: 150px;
    max-width: 150px;
}

/* image info */
.hn-img-thumbnail-frame:hover .hn-media-info {
    visibility: visible;
}

.hn-img-thumbnail-frame .hn-media-info {
    border-radius: 0 0 3px 0;
    box-shadow: 3px 3px 10px rgba(0,0,0,0.5);
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 0;
}

/* Date Picker */

.uib-datepicker-popup {
    padding: 10px 5px;
}

.uib-datepicker thead .uib-left,
.uib-datepicker thead .uib-right {
    border: none;
    background-color: transparent;
}

.uib-daypicker {
    outline: none;

}

.uib-day .btn.btn-default {
    border-color: transparent;
}

@media (min-width: 768px) {
    uiw-date-picker + input[type=checkbox],
    hn-date-picker + input[type=checkbox] {
        position: absolute;
        right: -5px;
        top: 6px;
    }
}

/* Text Angular */

.ta-toolbar {
    background-color: #ddd;
    border: 1px solid #ccc;
    border-radius: 3px 3px 0 0;
    margin-left: 0;
    padding: 5px;
    position: relative;
    z-index: 1;
}

.ta-toolbar + .ta-editor {
    border-radius: 0 0 3px 3px;
    border-top-width: 0;
}

@media (min-width: 768px) {
    .ta-toolbar .btn {
        padding: 3px 8px;
        line-height: 1.5;
        border-radius: 3px;
    }
}

/* angular-ui-switch */

.switch {
    background: transparent;
}

/* Text Blocks */

.hn-staff-notes {
    background-color: rgba(224, 224, 0, 0.4);
    border: 1px solid rgba(224, 224, 0, 0.8);
    color: rgb(156, 132, 46);
}

/* Drag n Drop */

.hn-drag-n-drop {
    background-color: #f5f5f5;
    margin-bottom: 15px;
    padding: 5px;
}
.hn-drag-n-drop .dropzone {
    background-color: transparent;
    border: dashed 2px lightgray;
    border-radius: 0;
    padding: 20px;
}

/* File Upload */

.hn-btn-choose-upload {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-bottom: 10px;
}

.hn-modal-image-library .tab-pane {
    padding-top: 15px;
}

.hn-or-separator {
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #ddd;
    height: 30px;
    line-height: 26px;
    margin: auto;
    text-align: center;
    width: 30px;
}

.hn-file-upload .hn-or-separator {
    margin: -25px auto -20px;
    position: relative;
    z-index: 2;
}

/* Icon Wrapper */

.hn-col-staff-notes .hn-staff-notes {
    overflow: hidden;
    overflow-y: auto;
    text-align: center;
}

.hn-col-staff-notes .hn-staff-notes .fa {
    opacity: 0.8;
}

/* Links */

.hn-link-crop {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
    white-space: nowrap;
}

/* Pagination */

.pagination>li>a,
.pagination>li>span {
    background: transparent;
    border: none;
    margin-left: 0;
    padding: 4px 10px;
}

.pagination>li>a:hover,
.pagination>li>span:hover,
.pagination>li>a:focus,
.pagination>li>span:focus {
    text-decoration: underline;
    background: transparent;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    background-color: rgb(120, 172, 217);
    box-shadow: 0 3px 3px rgba(0,0,0,0.3) inset;
    border-radius: 2px;
    text-decoration: none;
}

.pagination>.disabled>span,
.pagination>.disabled>span:hover,
.pagination>.disabled>span:focus,
.pagination>.disabled>a,
.pagination>.disabled>a:hover,
.pagination>.disabled>a:focus {
    background: transparent;
    opacity: .5;
}

@media (min-width: 768px) {
    .hn-results-actions .pagination {
        margin: 0;
        float: right;
    }
}

/* Sorting */

.hn-sort.fa-sort {
    opacity: .5;
}

a:hover .hn-sort.fa-sort {
    opacity: 1;
}

.hn-sort-active {
    font-weight: bold;
}

.hn-sort-style-line a {
    margin-left: 5px;
    text-decoration: none;
}

.hn-sort-style-line {
    margin-top: 15px;
}

@media (min-width: 768px) {
    .hn-sort-style-line {
        float: right;
        margin-top: 0;
    }
}

/* Popovers & Tooltips */

.tooltip-inner {
    word-break: break-all;
}

@media (min-width: 768px) {
    .tooltip-inner {
        max-width: 400px;
    }
}

.popover {
    border: 1px solid rgba(0, 0, 0, .3);
}

.tooltip-inner,
.popover {
    border-radius: 0;
}

.popover-title {
    border-radius: 0;
    margin: -1px -1px 0 -1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* Results */

.hn-results-actions {
    margin-bottom: 15px;
}

/* Tables */

.hn-layout-table {
    display: table;
    width: 100%;
}

.hn-layout-row {
    display: table-row;
}

.hn-layout-cell {
    display: table-cell;
    vertical-align: middle;
}

.hn-layout-top {
    vertical-align: top;
}


.hn-layout-table-padded .hn-layout-cell {
    padding: 5px;
}

.hn-layout-table-padded .hn-layout-cell:first-child {
    padding-left: 0;
}

.hn-layout-table-padded .hn-layout-cell:last-child {
    padding-right: 0;
}


.hn-sort {
    cursor: pointer;
}

.hn-col-title {
    width: 100%;
}

.hn-col-artist {
    white-space: nowrap;
    width: 30%;
}

.hn-col-alias {
    width: 70%;
}

.hn-row-actions {
    white-space: nowrap;
    width: 1%;
}

.table>tbody>tr>td.hn-row-actions {
    padding-right: 10px;
}

.hn-row-actions .btn-danger {
    margin-right: 10px;
}

.hn-table-hide-actions .hn-row-actions .btn {
    visibility: hidden;
}

.hn-table-hide-actions tr:hover .hn-row-actions .btn {
    visibility: visible;
}

tr.hn-first .hn-row-actions .hn-up,
tr.hn-last .hn-row-actions .hn-down {
    visibility: hidden;
}

.hn-col-address {
    min-width: 150px;
}

.hn-col-date {
    color: #777;
    min-width: 88px;
    max-width: 88px;
}

th.hn-col-published {
    width: 1%;
}

.hn-col-published {
    text-align: center;
}

.hn-col-staff-notes {
    min-width: 0;
    max-width: 50px;
    overflow: hidden;
}

.hn-col-tel {
    min-width: 90px;
    max-width: 90px;
}

/*
.table-condensed td {
    font-size: 90%;
}
*/


/* Tabs */

.uib-tab > div{position:relative;display:block;padding:10px 15px;outline:0;color:#337ab7;}.uib-tab > div:focus,.uib-tab > div:hover{background-color:#eee;color:#23527c;}.uib-tab.disabled > div{color:#777;}.uib-tab.disabled > div:focus,.uib-tab.disabled > div:hover{color:#777;cursor:not-allowed;background-color:transparent;}.nav-tabs > .uib-tab > div{margin-right:2px;line-height:1.42857143;border:1px solid transparent;border-radius:4px 4px 0 0;}.nav-tabs > .uib-tab > div:hover{border-color:#eee #eee #ddd;}.nav-tabs > .uib-tab.active > div,.nav-tabs > .uib-tab.active > div:focus,.nav-tabs > .uib-tab.active > div:hover{color:#555;cursor:default;background-color:#fff;border-color:#ddd #ddd transparent #ddd;}.nav-pills > .uib-tab > div{border-radius:4px;}.nav-pills > .uib-tab.active > div,.nav-pills > .uib-tab.active > div:focus,.nav-pills > .uib-tab.active > div:hover{color:#fff;background-color:#337ab7;}


/* Search Input */

input[type="search"] {
    -webkit-appearance: searchfield;
}

input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button;
}

.hn-refine-dropdown button::first-letter,
.hn-refine-dropdown li::first-letter {
    text-transform: capitalize;
}

.hn-fuzzy-date .hn-fuzzy-input {
    display: none;
}

.hn-fuzzy-date.hn-fuzzy hn-date-picker {
    display: none;
}
.hn-fuzzy-date.hn-fuzzy .hn-fuzzy-input {
    display: block;
}

.hn-date-override {
    padding-right: 15px;
    position: relative;
}

/* Typography */

.hn-text-right-responsive {
    text-align: left;
}

@media (min-width: 768px) {
    .hn-text-right-responsive {
        text-align: right;
    }
}

@media (max-width: 767px) {
    .hn-text-right-responsive {
        margin-bottom: 15px;
    }
}


/* Temporary SVG Icon */

.fa-uiw {
    width: 14px;
    height: 14px;
}
.icon-uiw-exhibition {
    color: inherit;
    fill: currentColor;
    vertical-align: text-top;
}

/* Content Overview */

.hn-overview-title {
    margin-top: 0;
}

.hn-overview-title > .small {
    margin-top: 10px;
}

.hn-overview-sitelink {
    display: inline-block;
    margin-bottom: 20px;
}

.hn-overview-summary {
    background-color: #f5f5f5;
    padding: 10px;
}

.hn-hr-subtle {
    border-top: 1px dotted #ddd;
}

.input-group-ui-select .ui-select-bootstrap>.ui-select-match>.btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

[hn-permission] {
    display: none !important;
}

[hn-permission].hn-auth-granted {
    display: block !important;
}

.btn[hn-permission].hn-auth-granted {
    display: inline-block !important;
}

.hn-modal-spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 5000;
}
